<template>
  <div class="c-uploader">
    <van-uploader
      v-model="fileList"
      :before-read="beforeRead"
      :after-read="afterRead"
      :max-size="size * 1024 * 1024"
      @oversize="onOversize"
      max-count="1"
      :accept="'image/*'"
      :upload-text="uploadText"
      :upload-icon="uploadIcon"
    >
    </van-uploader>
  </div>
</template>
<script>
import { uploadImg } from "@/api/sign";
import Compressor from "compressorjs";

export default {
  name: "CUploader",
  props: {
    uploadIconUrl: {
      type: String,
      default: "",
    },
    uploadText: {
      type: String,
      default: "上传文件",
    },
    size: {
      type: Number,
      default: 5,
    },
    uploadUrl: {
      type: Function
    },
  },
  data() {
    return {
      fileList: [],
    };
  },
  computed: {
    uploadIcon() {
      return this.uploadIconUrl;
    },
  },
  methods: {
    onOversize(file) {
      console.log(file);
      this.$toast.fail("文件大小不能超过 5M");
    },
    afterRead(file) {
      file.status = "uploading";
      file.message = "上传中...";
      let fd = new FormData();
      fd.append("file", file.file);
      let uploadUrl = this.uploadUrl ?? uploadImg;
      uploadUrl(fd)
        .then((res) => {
          console.log(res.data[0].imgUrl)
          file.status = "success";
          file.message = "";
          this.$emit("upload", res.data[0].imgUrl);
        })
        .catch(() => {
          file.status = "failed";
          file.message = "上传失败";
        });
    },
    beforeRead(file) {
      return this.compressImg(file);
    },
    compressImg(file) {
      return new Promise((resolve) => {
        // compressorjs 默认开启 checkOrientation 选项
        // 会将图片修正为正确方向
        new Compressor(file, {
          success: (res) => {
            resolve(res);
          },
          error(err) {
            console.log(err.message);
          },
        });
      });
    },
  },
};
</script>
<style lang="postcss" scoped>
.c-uploader {
  width: 100%;
  height: 100%;
}

.c-uploader >>> .van-uploader {
  display: block;
  width: 100%;
  height: 100%;
  padding: 5px;
}

.c-uploader >>> .van-uploader__wrapper {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  height: 100%;
}

.c-uploader >>> .van-uploader__upload,
.c-uploader >>> .van-image,
.c-uploader >>> .van-uploader__preview-image {
  width: 240px;
  height: 150px;
}

.c-uploader >>> .van-uploader__preview {
  margin: 0;
}

.c-uploader >>> .van-uploader__upload-text {
  margin-top: 8px;
  /* color: #969799;
    font-size: 12px; */
  position: absolute;
  bottom: 18px;
  width: 100%;
  height: 30px;
  line-height: 30px;
  /* background: #1da4f2; */
  color: #fff;
  text-align: center;
  font-size: 0.25rem;
  margin-top: 0.15rem;
  color: #666;
  font-weight: 600;
}

.c-uploader >>> .van-uploader__upload,
.c-uploader >>> .van-image {
  /* background: #E8F6FE; */
  /* border-radius: 10px; */
  /* overflow: hidden; */
  background: none;
}

.c-uploader >>> .van-uploader__upload {
  width: 100%;
  height: 100%;
  margin: 0;
}

.c-uploader >>> .van-uploader__upload-icon {
  font-size: 2rem;
}

.c-uploader >>> .van-icon__image {
  -o-object-fit: contain;
  object-fit: contain;
  margin-bottom: 23px;
}
</style>
